import axios  from 'axios';
import TokenService from '../TokenService/TokenService';
import {getConfig, objectToQueryString} from "../../utils/utils";
import {configuredStore} from "../../store/store";

let client = {};

client = axios.create({
	responseType: 'json',
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	},
	transformResponse: (response) => response
});


let requestCancelFunctions = {};
const storeRequestCancelFc = (requestId, cancelFc) => {
	requestCancelFunctions[requestId] = cancelFc;
};
client.interceptors.request.use(
	(config) => {
		const token = TokenService.getToken();
		const superUserState = configuredStore.getState().superuser;
		const {requestId, requiresSuperuser} = config;
		if(superUserState.overwriteCustomerId && !requiresSuperuser) {
			config.params = {
				...config.params,
				customerId: superUserState.overwriteCustomerId
			}
		}
		if (token) {
			config.headers.common['Authorization'] = token;
		}
		if(requestId) {
			requestCancelFunctions[requestId] && requestCancelFunctions[requestId]();
			config.cancelToken = new axios.CancelToken(storeRequestCancelFc.bind(null, requestId));
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

client.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		const errorResponse = error.response;
		if (errorResponse && (errorResponse.status === 403 || errorResponse.status === 401)) {
			TokenService.clearToken();
			window.document.location.reload();
		}
		return Promise.reject(error);
	}
);


const GET = async (
	url,
	data,
	axiosConfig
) => {
	const config = await getConfig();
	const queryString = data ? objectToQueryString(data) : null;
	const newUrl = queryString ? `${config.apiUrl}/${url}?${queryString}` : `${config.apiUrl}/${url}`;
	return client.get(newUrl, axiosConfig);
};

const PUT = async (
	url,
	data,
	axiosConfig
) => {
	const config = await getConfig();
	return client.put(`${config.apiUrl}/${url}`, data, axiosConfig);
};

const POST = async (
	url,
	data,
	axiosConfig
) => {
	const config = await getConfig();
	return client.post(`${config.apiUrl}/${url}`, data, axiosConfig);
};

const PATCH = async (
	url,
	data,
	axiosConfig
) => {
	const config = await getConfig();
	return client.patch(`${config.apiUrl}/${url}`, data, axiosConfig);
};

const DELETE = async (
	url,
	data
) => {
	const config = await getConfig();
	return client.delete(`${config.apiUrl}/${url}`, data);
};

export default {
	get: GET,
	post: POST,
	patch: PATCH,
	put: PUT,
	delete: DELETE
};
