const TokenService = (function() {
	function setToken(token) {
		localStorage.setItem('token', token);
	}
	function getToken() {
		return localStorage.getItem('token');
	}
	function clearToken() {
		localStorage.removeItem('token');
	}
	return {
		setToken: setToken,
		getToken: getToken,
		clearToken: clearToken
	};
})();
export default TokenService;
