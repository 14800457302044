import React, {useEffect} from 'react';
import { useLocation } from 'react-router-dom';
declare global {
    interface Window {
        _paq: any;
    }
}
const RouteChange = () => {
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;

        if(window._paq) {
            window._paq.push(['setCustomUrl', currentPath]);
            window._paq.push(['setDocumentTitle', currentPath]);
            window._paq.push(['setGenerationTimeMs', 0]);
            window._paq.push(['enableLinkTracking']);
            window._paq.push(['trackPageView']);
        }

    }, [location]);
    return(<span></span>);
}

export default RouteChange;