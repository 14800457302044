import {getDateWeek, getLastAvailableDate} from "../../utils/utils";
import CHART from "../../constants/CHART";
import {ChartActions, ChartState} from "./types";
import {UserActions} from "../user/types";
import CLIENT_CONFIGS from "src/constants/CLIENT_CONFIGS";

const initialState: ChartState = {
	hideTransactions: false,
	dateType: 'day',
	day: getLastAvailableDate().day,
	month: getLastAvailableDate().month,
	year: getLastAvailableDate().year,
	week: getDateWeek(),
	customDates: {
		show: false,
		start: null,
		end: null
	},
	weatherData: [],
	isWeatherDataFetching: false,
	yScaleCap: null,
	chartDataIdFocus: null,
	chartHighestShownValue: CHART.MINIMUM_Y_SCALE_VALUE,
	bookedScaleRatio: 1,
	data: {
		gps: {
			data: [],
			show: true,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false
		},
		maxFootfall: {
			data: [],
			show: false,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false
		},
		ots: {
			data: [],
			show: false,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false
		},
		visits: {
			data: [], 
			show: false,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false
		},
		transactions: {
			data: [],
			show: false,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false
		},
		booked: {
			data: [],
			show: false,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false
		},
		impressions: {
			data: [],
			show: false,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false
		},
		clicks: {
			data: [],
			show: false,
			highestValue: 0,
			enabled: true,
			isFetching: false,
			hidden: false 
		}
	},
	isChartDataFetching: false,
	stats: {
		estimatedPotential: {
			value: 0,
			isFetching: false
		},
		transactionsThisMonth: {
			value: 0,
			isFetching: false
		},
		newCustomersPercentage: {
			value: '0.00%',
			isFetching: false
		}
	}
};

export default function appReducer(state = initialState, action: ChartActions | UserActions) {
	switch (action.type) {
		case 'SET_GPS_DATA': {
			const highestValue = Math.max.apply(null, action.payload.map(item => Math.floor(item.count || 0)));
			return {
				...state,
				data: {
					...state.data,
					gps: {
						...state.data.gps,
						data: [
							...action.payload
						],
						highestValue,
						isFetching: false
					},
				}
			};
		}
		case 'SET_MAX_FOOTFALL_DATA': {
			const highestValue = Math.max.apply(null, action.payload.map(item => Math.floor(item.count || 0)));
			return {
				...state,
				data: {
					...state.data,
					maxFootfall: {
						...state.data.maxFootfall,
						data: [
							...action.payload
						],
						highestValue,
						isFetching: false
					}
				}
			};
		}
		case 'SET_OTS_DATA': {
			const highestValue = Math.max.apply(null, action.payload.map(item => Math.floor(item.count || 0)));
			return {
				...state,
				data: {
					...state.data,
					ots: {
						...state.data.ots,
						data: [
							...action.payload
						],
						highestValue,
						isFetching: false
					}
				}
			};
		}
		case 'SET_VISITOR_DATA': {
			const highestValue = Math.max.apply(null, action.payload.map(item => Math.floor(item.count || 0)));
			return {
				...state,
				data: {
					...state.data,
					visits: {
						...state.data.visits,
						data: [
							...action.payload
						],
						highestValue,
						isFetching: false
					}
				}
			};
		}
		case 'SET_SHOP_DATA': {
			const transactionsHighestValue = Math.max.apply(null, action.payload.transactionCount.map(item => Math.floor(item.count || 0)));
			const bookedHighestValue = Math.max.apply(null, action.payload.transactionRevenue.map(item => Math.floor(item.count || 0)));

			return {
				...state,
				data: {
					...state.data,
					transactions: {
						...state.data.transactions,
						data: [
							...action.payload.transactionCount
						],
						highestValue: transactionsHighestValue,
						isFetching: false
					},
					booked: {
						...state.data.booked,
						data: [
							...action.payload.transactionRevenue
						],
						highestValue: bookedHighestValue,
						isFetching: false
					}
				}
			};
		}
		case 'SET_BOOKED_SCALE_RATIO': {
			return {
				...state,
				bookedScaleRatio: action.payload
			};
		}
		case 'SET_IMPRESSIONS_DATA': {
			const highestValue = Math.max.apply(null, action.payload.map(item => Math.floor(item.count || 0)));
			return {
				...state,
				data: {
					...state.data,
					impressions: {
						...state.data.impressions,
						data: [
							...action.payload
						],
						highestValue,
						isFetching: false
					}
				}
			};
		}
		case 'SET_CLICKS_DATA': {
			const highestValue = Math.max.apply(null, action.payload.map(item => Math.floor(item.count || 0)));
			return {
				...state,
				data: {
					...state.data,
					clicks: {
						...state.data.clicks,
						data: [
							...action.payload
						],
						highestValue,
						isFetching: false
					}
				}
			};
		}
		case 'SET_WEATHER_DATA':
			return {
				...state,
				weatherData: action.payload,
				isWeatherDataFetching: false
			};
		case 'SET_IS_FETCHING_STATE_FOR_DATA':
			return {
				...state,
				data: {
					...state.data,
					[action.payload.sensorId]: {
						...state.data[action.payload.sensorId],
						isFetching: action.payload.isFetching
					}
				},
				isChartDataFetching: true
			};
		case 'SET_CHART_DATA_IS_FETCHING':
			return {
				...state,
				isChartDataFetching: action.payload
			};
		case 'SET_WEATHER_DATA_IS_FETCHING':
			return {
				...state,
				isWeatherDataFetching: true
			};
		case 'TOGGLE_SENSOR_DATA':
			return {
				...state,
				data: {
					...state.data,
					[action.payload.sensor]: {
						...state.data[action.payload.sensor],
						show: action.payload.enabled
					}
				}
			};
		case 'SET_CHART_HIGHEST_SHOWN_VALUE':
			return {
				...state,
				chartHighestShownValue: action.payload
			};
		case 'FETCH_ESTIMATED_POTENTIAL':
			return {
				...state,
				stats: {
					...state.stats,
					estimatedPotential: {
						isFetching: true
					}
				}
			};
		case 'SET_ESTIMATED_POTENTIAL':
			return {
				...state,
				stats: {
					...state.stats,
					estimatedPotential: {
						value: action.payload,
						isFetching: false
					}
				}
			};
		case 'FETCH_TRANSACTIONS_THIS_MONTH':
			return {
				...state,
				stats: {
					...state.stats,
					transactionsThisMonth: {
						isFetching: true
					}
				}
			};
		case 'SET_TRANSACTIONS_THIS_MONTH':
			return {
				...state,
				stats: {
					...state.stats,
					transactionsThisMonth: {
						value: action.payload,
						isFetching: false
					}
				}
			};
		case 'FETCH_NEW_CUSTOMERS_PERCENTAGE':
			return {
				...state,
				stats: {
					...state.stats,
					newCustomersPercentage: {
						isFetching: true
					}
				}
			};
		case 'SET_NEW_CUSTOMERS_PERCENTAGE':
			return {
				...state,
				stats: {
					...state.stats,
					newCustomersPercentage: {
						value: action.payload,
						isFetching: false
					}
				}
			};
		case 'RESET_DATA':
			return {
				...state,
				data: {
					...state.data,
					[action.payload]: []
				}
			};
		case 'SET_DATE_TYPE':
			return {
				...state,
				dateType: action.payload
			};
		case 'UPDATE_DATE':
			return {
				...state,
				[action.payload.type]: action.payload.value
			};
		case 'SET_CUSTOM_DATES':
			return {
				...state,
				customDates: action.payload
			};
		case 'SET_SHOW_CUSTOM_DATES':
			return {
				...state,
				customDates: {
					...state.customDates,
					show: action.payload
				}
			};
		case 'SET_CURRENT_LOCATION':
			const hasSensorId = action.payload.hasSensor;
			return {
				...state,
				data: {
					...state.data,
					maxFootfall: {
						...state.data.maxFootfall,
						enabled: hasSensorId
					},
					ots: {
						...state.data.ots,
						enabled: hasSensorId
					},
					visits: {
						...state.data.visits,
						enabled: hasSensorId
					}
				}
			};
		case 'SET_YSCALE_CAP':
			return {
				...state,
				yScaleCap: action.payload
			};
		case 'SET_CHART_DATA_ID_FOCUS':
			return {
				...state,
				chartDataIdFocus: action.payload
			};
		case 'SET_CUSTOMER_DATA': {
			const customerData = action.payload.customer;
			const clientConfig = CLIENT_CONFIGS.find(config => customerData.name === config.name);
			
			return {
				...state,
				data: {
					...state.data,
					gps: {
						...state.data.gps,
						show: clientConfig?.defaultLayer ? clientConfig?.defaultLayer === 'gps' : true,  
						hidden: clientConfig?.show.indexOf('gps') === -1
					},
					maxFootfall: {
						...state.data.maxFootfall,
						show: clientConfig?.defaultLayer ? clientConfig?.defaultLayer === 'maxFootfall' : false,
						hidden: clientConfig?.show.indexOf('maxFootfall') === -1
					},
					visits: {
						...state.data.maxFootfall,
						show: clientConfig?.defaultLayer ? clientConfig?.defaultLayer === 'visits' : false,
						hidden: clientConfig?.show.indexOf('visits') === -1
					},
					transactions: {
						...state.data.transactions,
						show: clientConfig?.defaultLayer ? clientConfig?.defaultLayer === 'transactions' : false,
						hidden: clientConfig?.show.indexOf('transactions') === -1
					},
					booked: {
						...state.data.booked,
						show: clientConfig?.defaultLayer ? clientConfig?.defaultLayer === 'booked' : false,
						hidden: clientConfig?.show.indexOf('booked') === -1
					},
					impressions: {
						...state.data.impressions,
						show: clientConfig?.defaultLayer ? clientConfig?.defaultLayer === 'impressions' : false,
						hidden: clientConfig?.show.indexOf('impressions') === -1
					},
					clicks: {
						...state.data.clicks,
						show: clientConfig?.defaultLayer ? clientConfig?.defaultLayer === 'clicks' : false,
						hidden: clientConfig?.show.indexOf('clicks') === -1
					}
				},
			}
		}
		default:
			return state;
	}

}
