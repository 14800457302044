import * as React from "react";
import TokenService from "../../services/TokenService/TokenService";
import {getUrlParams} from "../../utils/utils";
import {useEffect} from "react";
import {getCustomerData, setShowLoader} from "../../store/user/actions";
import {connect} from "react-redux";
import {DispatchActions, MapStateToProps, SignedInProps} from "./types";
import {RootState} from "../../store/store";
import {AppThunkDispatch} from "../../types";

const SignedIn = (props: SignedInProps) => {

	useEffect(() => {
		props.setShowLoader(true);
		props.getCustomerData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if(props.user.customerData) {
			props.history?.push('/home');
			props.setShowLoader(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.user.customerData]);

	const params = getUrlParams(props.history.location.hash);
	let token = TokenService.getToken();

	if(params.id_token) {
		token = params.id_token;
		TokenService.setToken(token);
	}

	if(!token) {
		console.error('No id_token present in url or localStorage, redirecting...');
		props.history.push('/');
	}

	return  (
		<React.Fragment />
	)
};

const mapStateToProps = (state: RootState): MapStateToProps => ({
	user: state.user,
	campaign: state.campaign
});

const mapDispatchToProps = (
	dispatch: AppThunkDispatch
): DispatchActions => ({
	getCustomerData: () => dispatch(getCustomerData()),
	setShowLoader: (show) => dispatch(setShowLoader(show))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignedIn);