import SENSORS from "../../constants/SENSORS";
import CHART from "../../constants/CHART";

export default (store: any) => (next: any) => {
	return (action: any) => {
		next(action);
		if (
			action.type === 'SET_GPS_DATA' ||
			action.type === 'SET_MAX_FOOTFALL_DATA' ||
			action.type === 'SET_OTS_DATA' ||
			action.type === 'SET_VISITOR_DATA' ||
			action.type === 'SET_SHOP_DATA' ||
			action.type === 'SET_IMPRESSIONS_DATA' ||
			action.type === 'SET_CLICKS_DATA' ||
			action.type === 'TOGGLE_SENSOR_DATA'
		) {
			const state = store.getState();
			let highestValue = CHART.MINIMUM_Y_SCALE_VALUE
			for (let data in state.chart.data) {
				if(state.chart.data[data].show && data !== SENSORS.BOOKED.ID) {
					highestValue = Math.max(state.chart.data[data].highestValue, highestValue);
				}
			}
			store.dispatch({
				type: 'SET_CHART_HIGHEST_SHOWN_VALUE',
				payload: Math.max(highestValue, CHART.MINIMUM_Y_SCALE_VALUE)
			});

			const highestBookedValue = state.chart.data[SENSORS.BOOKED.ID].highestValue;
			if(highestBookedValue && highestValue) {
				store.dispatch({
					type: 'SET_BOOKED_SCALE_RATIO',
					payload: highestValue / highestBookedValue
				});
			}
		}
	};
};