export interface ISensorProp {
	[key: string]: string
}

export interface ISensorsProp {
	[key: string]: ISensorProp
}

const SENSORS: ISensorsProp = {
	GPS: {
		ID: 'gps',
		LABEL: 'GPS',
		ACTION: 'getGpsData',
		RGB: 'rgb(82, 185, 168)'
	},
	MAX_FOOTFALL: {
		ID: 'maxFootfall',
		LABEL: 'Maximum Footfall',
		ACTION: 'getMaxFootfallData',
		RGB: 'rgb(215, 75, 140)'
	},
	OTS: {
		ID: 'ots',
		LABEL: 'OTS',
		ACTION: 'getOtsData',
		RGB: 'rgb(215, 75, 140)'
	},
	VISITS: {
		ID: 'visits',
		LABEL: 'Visits',
		ACTION: 'getVisitorData',
		RGB: 'rgb(240, 135, 145)'
	},
	TRANSACTIONS: {
		ID: 'transactions',
		LABEL: 'Transactions',
		ACTION: 'getShopData',
		RGB: 'rgb(95, 140, 160)'
	},
	BOOKED: {
		ID: 'booked',
		LABEL: 'Revenue',
		ACTION: 'getShopData',
		RGB: 'rgb(65, 180, 210)'
	},
	IMPRESSIONS: {
		ID: 'impressions',
		LABEL: 'Contacts',
		ACTION: 'getImpressionsData',
		RGB: 'rgb(147, 208, 118)'
	},
	CLICKS: {
		ID: 'clicks',
		LABEL: 'Engagements',
		ACTION: 'getClicksData',
		RGB: 'rgb(67, 81, 207)'
	}
};

export default SENSORS;