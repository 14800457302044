import API from "../../services/API/API";
import axios from "axios";
import {API_PATHS} from "../../constants/API";
import {AppThunk} from "../../types";
import {ICampaignData} from "../../services/API/types";

export const getCampaignData = (locationId: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		dispatch({
			type: 'SHOW_LOADER',
			payload: true
		});
		API.get(`${API_PATHS.CAMPAIGN}/${locationId}`)
			.then(response => {
				dispatch({
					type: 'SET_CAMPAIGN_DATA',
					payload: response.data
				});
				dispatch({
					type: 'SET_CAMPAIGN_FETCH_ERROR',
					payload: false
				});
				dispatch({
					type: 'SET_IS_NEW_CAMPAIGN',
					payload: false
				});
				dispatch({
					type: 'SHOW_LOADER',
					payload: false
				});
			}).catch(() => {
				dispatch({
					type: 'SET_CAMPAIGN_DATA',
					payload: null
				});
				dispatch({
					type: 'SET_CAMPAIGN_FETCH_ERROR',
					payload: true
				});
				dispatch({
					type: 'SHOW_LOADER',
					payload: false
				});
			});
	};
};
export const createCampaign = (locationId: string, adPackage: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.post(`campaigns/${locationId}`, {
			adPackage: adPackage
		}).then(response => {
			dispatch({
				type: 'SET_CAMPAIGN_DATA',
				payload: response.data
			});
			dispatch({
				type: 'SET_CAMPAIGN_FETCH_ERROR',
				payload: false
			});
			dispatch({
				type: 'SET_IS_NEW_CAMPAIGN',
				payload: true
			});
		});
	};
};
export const updateCampaign = (locationId: string, campaignData: ICampaignData): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.patch(`campaigns/${locationId}`, campaignData).then(response => {
			dispatch({
				type: 'SET_CAMPAIGN_DATA',
				payload: response.data
			});
			dispatch({
				type: 'SET_CAMPAIGN_FETCH_ERROR',
				payload: false
			});
			dispatch({
				type: 'SET_IS_NEW_CAMPAIGN',
				payload: false
			});
		});
	};
};
export const deleteCampaign = (locationId: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.delete(`campaigns/${locationId}`).then(() => {
			dispatch({
				type: 'SET_CAMPAIGN_DATA',
				payload: null
			});
			dispatch({
				type: 'SET_CAMPAIGN_FETCH_ERROR',
				payload: false
			});
			dispatch({
				type: 'SET_IS_NEW_CAMPAIGN',
				payload: false
			});
		});
	};
};
export const updateCampaignLandingUrl = (url: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		dispatch({
			type: 'SET_CUSTOM_LANDING_URL',
			payload: url
		});
	};
};
export const getCampaignDefaultAdunit = (locationId:string, adunitId:string): AppThunk => {
	return async (dispatch): Promise<void> => {
		API.get(`adunits/${adunitId}`)
			.then(response => {
				dispatch({
					type: 'SET_CAMPAIGN_DEFAULT_ADUNIT',
					payload: response.data
				});
			});
	};
};
export const getCampaignCustomAdunit = (locationId:string, adunitId:string): AppThunk => {
	return async (dispatch): Promise<void> => {
		API.get(`adunits/${adunitId}`)
			.then(response => {
				dispatch({
					type: 'SET_CAMPAIGN_CUSTOM_ADUNIT',
					payload: response.data
				});
			});
	};
};
export const buildCampaignCustomAdunit = (locationId:string, customAdUnitConfig:{}): AppThunk => {
	return async (dispatch): Promise<void> => {
		return API.post(`adunits/`, customAdUnitConfig)
			.then(response => {
				dispatch({
					type: 'SET_CAMPAIGN_CUSTOM_ADUNIT',
					payload: response.data
				});
			});
	};
};
export const uploadAsset = (file:File) => {
	return async (): Promise<string> => {
		try {
			let getUrl = "";
			await API.post(`/assets?filename=${file.name}`).then(async (response) => {
				await axios.put(response.data.putUrl, file, {
					headers: {
						'Content-Type': file.type
					}
				});
				getUrl = response.data.getUrl;
			});
			return getUrl;
		} catch(error) {
			throw(error);
		}
	}
};