import PACKAGES from "../../constants/PACKAGES";
import {UserActions, UserState} from "./types";

const initialState: UserState = {
	isLoggedIn: true,
	customerData: null,
	currentLocation: null,
	currentPackage: PACKAGES.CUSTOM,
	language: 'en',
	hasSensorData: false,
	showLoader: false,
	superuser: false,
	manager: false,
	overwriteUserCredentials: false
};

export default function userReducer(state = initialState, action: UserActions) {
	switch (action.type) {
		case 'LOGIN_USER': {
			return {
				...state,
				isLoggedIn: action.payload
			};
		}
		case 'SET_CUSTOMER_DATA': {
			return {
				...state,
				customerData: action.payload,
				currentLocation: action.payload.locations[0],
				superuser: action.payload.user.type === 'superuser',
				manager: action.payload.user.type === 'manager'
			}
		}
		case 'SET_CURRENT_LOCATION': {
			return {
				...state,
				currentLocation: action.payload,
				hasSensorData: action.payload.hasSensor
			}
		}
		case 'SET_LANGUAGE': {
			return {
				...state,
				language: action.payload
			}
		}
		case 'SHOW_LOADER': {
			return {
				...state,
				showLoader: action.payload
			}
		}
		default:
			return state;
	}
}
