import PACKAGES from "../../constants/PACKAGES";
import {CampaignActions, CampaignState} from "./types";
import {UserActions} from "../user/types";

const initialState: CampaignState = {
	isNewCampaign: false,
	campaignData: null,
	campaignFetchError: false,
	campaignIsFetching: false,
	newAdunit: false,
	customLandingUrl: null,
	package: PACKAGES.ESSENTIAL
};

export default function appReducer(state = initialState, action: CampaignActions | UserActions) {
	switch (action.type) {
		case 'SET_CAMPAIGN_DATA':
			let newPackage = null;
			if(action.payload?.adPackage) {
				newPackage = PACKAGES[Object.keys(PACKAGES).find(thisPackage => PACKAGES[thisPackage].ID === action.payload.adPackage)!];
			}
			return {
				...state,
				campaignData: action.payload,
				package: newPackage,
				newAdunit: false
			};
		case 'SET_CUSTOM_LANDING_URL':
			return {
				...state,
				customLandingUrl: action.payload
			};
		case 'SET_CAMPAIGN_DEFAULT_ADUNIT':
			return {
				...state,
				campaignData: {
					...state.campaignData,
					defaultAdunits: [
						{
							...state.campaignData?.defaultAdunits[0],
							...action.payload,
							previewUrl: action.payload.previewUrl || ""
						}
					]
				}
			};
		case 'SET_CAMPAIGN_CUSTOM_ADUNIT':
			return {
				...state,
				campaignData: {
					...state.campaignData,
					adunits: [{
						...state.campaignData?.adunits[0],
						...action.payload,
						previewUrl: action.payload.previewUrl || ""
					}]
				},
				newAdunit: !!action.payload.previewUrl
			};
		case 'SET_CURRENT_LOCATION':
			return {
				...state,
				newAdunit: false
			};
		case 'SET_CAMPAIGN_FETCH_ERROR':
			return {
				...state,
				campaignFetchError: action.payload
			};
		case 'SET_IS_NEW_CAMPAIGN':
			return {
				...state,
				isNewCampaign: action.payload
			};
		case 'SET_CAMPAIGN_IS_FETCHING':
			return {
				...state,
				campaignIsFetching: action.payload
			};
		default:
			return state;
	}
}
