import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import {ICongratsProps} from "./types";
const Congrats = (props:ICongratsProps) => {
	const { t } = useTranslation(undefined, { useSuspense: false });

	const onContactClick = () => {
		props.history.push('/contact');
	};
	return (
		<div className="screen congratsScreen congratsScreen--large">
			<div className="screen__content">
				<div className="congratsScreen__guarantee" />
				<div className="congratsScreen__bg">
					<p className="congratsScreen__congratsText"><Trans t={t}>congratulations.congratulations</Trans></p>
					<p className="congratsScreen__thanksText"><Trans t={t}>congratulations.thanks</Trans></p>
					<div className="congratsScreen__package">
						<h2 className="congratsScreen__packageTitle"><strong>Premium</strong> Media</h2>
						<p className="congratsScreen__packageSubtitle"><Trans t={t}>packages.package</Trans></p>
					</div>
				</div>
				<p className="congratsScreen__note"><Trans t={t}>congratulations.bookingNote</Trans></p>
				<button className="button button--gradient1 text-uppercase" onClick={onContactClick}><Trans t={t}>congratulations.customerServiceBtn</Trans></button>
			</div>
		</div>
	);
};

export default Congrats;