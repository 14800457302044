import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export default createMuiTheme({
	palette: {
		primary: {
			main: '#2674a8'
		},
		secondary: {
			main: '#7527f9'
		},
		text: {
			primary: '#646464',
			secondary: '#fff'
		},
		action: {
			disabledBackground: 'rgba(255,255,255,0.5)',
			disabled: '#000'
		}
	},
	typography: {
		fontFamily: ['Roboto', '"Open Sans"', 'Arial', 'sans-serif'].join(','),
		fontSize: 16,
		body1: {
			fontSize: 16
		},
		body2: {
			fontSize: 12
		}
	}
});
