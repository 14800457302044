import API from "../../services/API/API";
import {AppSync, AppThunk} from "../../types";
import {ICustomerUserData} from "../../services/API/types";

export const getCustomers = (): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.get('customers', {},
			{
				requiresSuperuser: true
			}).catch(() => {
		});
	}
}
export const getUsers = (customerId: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.get('users', {customerId},
			{
				requiresSuperuser: true
			}).catch(() => {

		});
	}
}
export const getSensor = (locationId: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.get(`sensors/${locationId}`, {},
			{
				requiresSuperuser: true
			});
	}
}
export const putSensor = (locationId: string, sensorId: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.put(`sensors/${locationId}?sensorId=${sensorId}`, {},
			{
				requiresSuperuser: true
			});
	}
}
export const getCoordinates = (locationId: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.get(`coordinates/${locationId}`, {},
			{
				requiresSuperuser: true
			});
	}
}
export const putCoordinates = (locationId: string, lat: string, lon: string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.put(`coordinates/${locationId}?lat=${lat}&lon=${lon}`, {},
			{
				requiresSuperuser: true
			});
	}
}
export const addCustomer = (customerData: ICustomerUserData): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.post(`customers`, customerData,
			{
				requiresSuperuser: true
			});
	}
}
export const addUser = (userData: any, customerId:string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.post(`users?customerId=${customerId}`, userData,
			{
				requiresSuperuser: true
			});
	}
}
export const addLocation = (locationData: any, customerId:string): AppThunk => {
	return async (dispatch): Promise<void> => {
		return await API.post(`locations?customerId=${customerId}`, locationData,
			{
				requiresSuperuser: true
			});
	}
}
export const setOverwriteCustomerId = (customerId: string | null): AppSync => {
	return (dispatch): void => {
		dispatch({
			type: 'SET_OVERWRITE_CUSTOMER_ID',
			payload: customerId
		});
	};
};