import * as React from 'react';
import {ChangeEvent, useState} from "react";
import { useForm } from 'react-hubspot';
import {Trans, useTranslation} from "react-i18next";

const Contact = () => {

	const { t } = useTranslation(undefined, { useSuspense: false });

	const [messageSent, setMessageSent] = useState(false);
	const { handleSubmit } = useForm({
		portalId: "6834478",
		formId: "72420de4-e9c3-4e22-8ea3-2d30e2ae7bbf",
	});

	const onScheduleClick = () => {
		window.open("https://meetings.hubspot.com/manuel66", '_blank');
	};

	const onFormSubmit = (e: ChangeEvent<HTMLFormElement>) => {
		setMessageSent(true);
		handleSubmit(e);
	};

	return (
		<div className="screen contactScreen">
			<div className="screen__content">
				{messageSent
					?
						<h2 className="contactScreen__msgSent"><Trans t={t}>contact.yourMessageHasBeenSent</Trans></h2>
					: ""
				}
				<form
					className="contactScreen__form"
					onSubmit={onFormSubmit}
				>
					<p className="contactScreen__topNote"><Trans t={t}>contact.scheduleInfo</Trans></p>
					<button
						type="button"
						className="contactScreen__scheduleButton button button--gradient2"
						onClick={onScheduleClick}
					>
						<Trans t={t}>contact.schedule</Trans>
					</button>
					<p className="contactScreen__orContactUsText"><Trans t={t}>contact.orMessageUs</Trans></p>
					<h2><Trans t={t}>contact.contactUs</Trans></h2>

					<input name="firstname" type="text" placeholder={t('contact.fullName')} />
					<input name="email" type="email" placeholder={t('contact.email')} required />
					<input name="phone" type="number" placeholder={t('contact.phone')} required />
					<textarea name="message" rows={6} placeholder={t('contact.yourMessage')} required />
					{/* @ts-ignore */}
					<input className="button button--primary" type="submit" value={t('contact.submit')}/>
				</form>
			</div>
		</div>
	);
};

export default Contact;