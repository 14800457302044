const LanguageService = (function() {
	function setLanguage(lang) {
		localStorage.setItem('language', lang);
	}
	function getLanguage() {
		return localStorage.getItem('language');
	}
	return {
		setLanguage,
		getLanguage
	};
})();
export default LanguageService;
