import CHART from "../constants/CHART";
import moment from "moment-timezone";
import {GRANULARITY} from "../constants/API";
import currencyFormatter from 'currency-formatter';
import SENSORS from "../constants/SENSORS";
import {ChartState} from "../store/chart/types";
import {IDataPoint, ILocation} from "../services/API/types";

export const parseApiData = (chartData: ChartState, location: ILocation, sensor: string) => {
	const sensorData = chartData.data[sensor].data;
	const lastItem: any = sensorData ? sensorData[sensorData.length - 1] : null;
	let parsedData = sensorData.map((item: IDataPoint) => {
		return {
			x: formatIsoDate(item.start, location.coordinates.timezone),
			y: chartData.yScaleCap ? item.count && item.count > chartData.yScaleCap && sensor !== SENSORS.BOOKED.ID ? null : item.count : item.count
		}
	});
	if(lastItem) {
		parsedData.push({
			x: formatIsoDate(lastItem.end, location.coordinates.timezone),
			y: lastItem.count
		});
	}
	if(sensor === SENSORS.BOOKED.ID) {
		parsedData = parsedData.map(item => {
			const scaledYValue = item.y === null ? null : item.y * chartData.bookedScaleRatio;
			return {
				x: item.x,
				y: chartData.yScaleCap && scaledYValue
					? scaledYValue > chartData.yScaleCap
						? null : scaledYValue
					: scaledYValue
			}
		});
	}
	return parsedData;
};

export const formatIsoDate = (isoDate: string, timezone: string) => {
	return moment(isoDate).set({minutes:0, seconds:0}).tz(timezone).format('YYYY-MM-DD HH:mm');
};

export const getDateWeek = () => {
	let now: Date = new Date();
	now = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
	now.setUTCDate(now.getUTCDate() + 4 - (now.getUTCDay()||7));
	let yearStart: Date = new Date(Date.UTC(now.getUTCFullYear(),0,1));
	// @ts-ignore
	return String(Math.ceil(( ( (now - yearStart) / 86400000) + 1)/7));
};

export const getWeekRangeFromWeekNumber = (w: string, y: string) => {
	let dateStart = new Date(Date.UTC(+y, 0, 1 + (+w - 1) * 7)),
		dateEnd = new Date(Date.UTC(+y, 0, 1 + (+w - 1) * 7));
	let dow = dateStart.getDay();
	let IsoWeekStart = dateStart;
	let IsoWeekEnd = dateEnd;
	if (dow <= 4) {
		IsoWeekStart.setDate(dateStart.getDate() - dateStart.getDay() + 1);
		IsoWeekEnd.setDate((dateEnd.getDate() - dateEnd.getDay() + 1 ) + 7);
	} else {
		IsoWeekStart.setDate(dateStart.getDate() + 8 - dateStart.getDay());
		IsoWeekEnd.setDate((dateEnd.getDate() + 8 - dateEnd.getDay()) + 7);
	}
	IsoWeekStart = new Date(IsoWeekStart);
	const IsoWeekStartMonth = (IsoWeekStart.getMonth() + 1) < 10 ? `0${IsoWeekStart.getMonth() + 1}` : IsoWeekStart.getMonth() + 1;
	const IsoWeekStartDay = IsoWeekStart.getDate() < 10 ? `0${IsoWeekStart.getDate()}` : IsoWeekStart.getDate();
	IsoWeekEnd = new Date(IsoWeekEnd);
	const IsoWeekEndMonth = (IsoWeekEnd.getMonth() + 1) < 10 ? `0${IsoWeekEnd.getMonth() + 1}` : IsoWeekEnd.getMonth() + 1;
	const IsoWeekEndDay = IsoWeekEnd.getDate() < 10 ? `0${IsoWeekEnd.getDate()}` : IsoWeekEnd.getDate();
	return {
		start: `${IsoWeekStart.getFullYear()}-${IsoWeekStartMonth}-${IsoWeekStartDay}`,
		end: `${IsoWeekEnd.getFullYear()}-${IsoWeekEndMonth}-${IsoWeekEndDay}`
	};
};

interface INewDates {
	start?: string
	end?: string
}
export const getChartDates = (chartData: ChartState) => {
	let newDates:INewDates = {};

	let currentYear = getLastAvailableDate().year;
	let nextMonthYear = getLastAvailableDate().year;

	const currentDate = moment(new Date(`${currentYear}-${getLastAvailableDate().month}-${chartData.day}`)).format('YYYY-MM-DD');
	let tomorrow = moment(new Date(currentDate).setDate(new Date(currentDate).getDate() + 1)).format('YYYY-MM-DD');
	let nextMonth = (+chartData.month + 1) < 10 ? `0${+chartData.month + 1}` : `${Number(chartData.month) + 1}`;

	if(nextMonth === '13') {
		nextMonthYear = String(Number(currentYear) + 1);
		nextMonth = '01';
	}

	if(chartData.dateType === CHART.DATE_TYPES.DAY) { 
		
		newDates.start = currentDate;
		newDates.end = tomorrow;
	}
	if(chartData.dateType === CHART.DATE_TYPES.WEEK) {
		newDates.start = getWeekRangeFromWeekNumber(chartData.week, String(new Date().getFullYear())).start;
		newDates.end = getWeekRangeFromWeekNumber(chartData.week, String(new Date().getFullYear())).end;
	}
	if(chartData.dateType === CHART.DATE_TYPES.MONTH) {
		newDates.start = `${currentYear}-${chartData.month}-01`;
		newDates.end = `${nextMonthYear}-${nextMonth}-01`;
	}
	
	return {
		start: newDates.start,
		end: newDates.end
	}
};

export const parseJwt = (token: string) => {
	let base64Url = token.split(".")[1];
	let base64 = base64Url.replace("-", "+").replace("_", "/");
	return JSON.parse(window.atob(base64));
};

export const getUrlParams = (url: string): any => {
	return url.substring(1).split("&")
		.map((v:string) => v.split("="))
		.reduce( (pre, [key, value]) => ({ ...pre, [key]: value }), {} );
};

export const objectToQueryString = (object: any) => {
	return Object.keys(object).map(key => key + '=' + object[key]).join('&');
};

let config: any = null;
const fetchConfig = async () => {
	return await fetch("/config.json").then(response => response.json());
};

export const getConfig = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		if(config) {
			resolve(config)
		} else {
			fetchConfig().then(thisConfig => {config = thisConfig; resolve(thisConfig)}).catch((e) => reject(e));
		}
	});
};

export const getLastAvailableDate = () => {
	const yesterday = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);

	const month = yesterday.getMonth() + 1;
	const day = yesterday.getDate();

	return {
		day: day < 10 ? `0${day}` : String(day),
		month: month < 10 ? `0${month}` : String(month),
		year: String(yesterday.getFullYear())
	};
};

export const getGranularity = (chartData:ChartState, isForWeather?:boolean) => {
	const DATE_TYPE = Object.keys(CHART.DATE_TYPES).find((dateType: string) => CHART.DATE_TYPES[dateType] === chartData.dateType);
	let granularity = CHART.GRANULARITY[DATE_TYPE!];

	const chartGranularityFromDays = {
		hour: 8,
		day: 210,
		week: 730
	};
	if(!isForWeather) {
		if(chartData.dateType === CHART.DATE_TYPES.CUSTOM && chartData.customDates.start && chartData.customDates.end) {
			const startDate = moment(new Date(chartData.customDates.start));
			const endDate = moment(new Date(chartData.customDates.end));
			const daysSelected = Math.abs(startDate.diff(endDate, 'days'));

			if (daysSelected <= chartGranularityFromDays.hour) {
				granularity = GRANULARITY.HOUR;
			} else if (daysSelected <= chartGranularityFromDays.day) {
				granularity = GRANULARITY.DAY;
			} else if(daysSelected <= chartGranularityFromDays.week) {
				granularity = GRANULARITY.WEEK;
			} else if(daysSelected > chartGranularityFromDays.week){
				granularity = GRANULARITY.MONTH;
			}
		}
	}

	const weatherGranularityFromDays = {
		hour: 1,
		day: 56,
		week: 240
	};
	if(isForWeather) {
		if(chartData.dateType === CHART.DATE_TYPES.WEEK || chartData.dateType === CHART.DATE_TYPES.MONTH) {
			granularity = GRANULARITY.DAY;
		}
		if(chartData.dateType === CHART.DATE_TYPES.CUSTOM && chartData.customDates.start && chartData.customDates.end) {
			const startDate = moment(new Date(chartData.customDates.start));
			const endDate = moment(new Date(chartData.customDates.end));
			const daysSelected = Math.abs(startDate.diff(endDate, 'days'));

			if (daysSelected <= weatherGranularityFromDays.hour) {
				granularity = GRANULARITY.HOUR;
			} else if (daysSelected <= weatherGranularityFromDays.day) {
				granularity = GRANULARITY.DAY;
			} else if(daysSelected <= weatherGranularityFromDays.week) {
				granularity = GRANULARITY.WEEK;
			} else if(daysSelected > weatherGranularityFromDays.week){
				granularity = GRANULARITY.MONTH;
			}
		}
	}
	return granularity;
};

export const detectIE = () => {
	let ua = window.navigator.userAgent;
	let result : boolean|number = false;

	let msie = ua.indexOf('MSIE ');
	if (msie > 0) {
		// IE 10 or older => return version number
		result = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
	}

	let trident = ua.indexOf('Trident/');
	if (trident > 0) {
		// IE 11 => return version number
		let rv = ua.indexOf('rv:');
		result = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
	}

	let edge = ua.indexOf('Edge/');
	if (edge > 0) {
		// Edge (IE 12+) => return version number
		result = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
	}

	return result;
};
export const getError = (e: any): string => {
	return `Error: ${String(e.response?.data?.error || "Internal server error")}`
};
export const formatCurrency = (value:number, options:any) => {
	return currencyFormatter.format(value, {
		thousand: '.',
		decimal: ',',
		precision: 0,
		...options
	});
};