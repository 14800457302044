import React from 'react';
import { IAdminMenuProps } from './types';
import {Button} from "@material-ui/core";
import {useHistory} from 'react-router-dom';

const Congrats = (props:IAdminMenuProps) => {
    const history = useHistory();
    return (
        <div className="adminMenu">
            <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                    history.push('/home')
                }}
            >
                Dashboard
            </Button>
            <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => {
                    history.push('/customers')
                }}
            >
                Customer List
            </Button>
        </div>
    );
};

export default Congrats;