import React from  'react';
import {ErrorBoundaryProps} from "./types";

import * as Sentry from "@sentry/react";

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
    state = {
        error: '',
        errorInfo: '',
        eventId: '',
        hasError: false
    };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        Sentry.withScope((scope) => {//@ts-ignore
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            window._paq && window._paq.push(['trackEvent', 'Error Boundary', 'Stack Trace', errorInfo.componentStack]);
            this.setState({ eventId, errorInfo }); 
        });
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;