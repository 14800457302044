export default (store: any) => (next: any) => {
	return (action: any) => {
		next(action);
		if (
			action.type === 'SET_GPS_DATA' ||
			action.type === 'SET_MAX_FOOTFALL_DATA' ||
			action.type === 'SET_OTS_DATA' ||
			action.type === 'SET_VISITOR_DATA' ||
			action.type === 'SET_SHOP_DATA' ||
			action.type === 'SET_IMPRESSIONS_DATA' ||
			action.type === 'SET_CLICKS_DATA' ||
			action.type === 'SET_IS_FETCHING_STATE_FOR_DATA'
		) {
			const state = store.getState();
			let isDataBeingFetched = false;
			for (let data in state.chart.data) {
				if (state.chart.data[data].isFetching) {
					isDataBeingFetched = true;
				}
			}
			store.dispatch({
				type: 'SET_CHART_DATA_IS_FETCHING',
				payload: isDataBeingFetched
			});
		}
	};
};