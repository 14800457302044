const CLIENT_CONFIGS = [
	{
		id: 'expo',
		name: "EXPO Energietechnik",
		defaultLayer: 'maxFootfall',
		show: ['maxFootfall', 'ots', 'visits'],
		hideFromCustomerList: true,
		enableBooking: false,
	},
	{
		id: 'orb',
		name: "ORB test",
		defaultLayer: 'maxFootfall',
		show: ['maxFootfall', 'ots', 'visits'],
		hideFromCustomerList: true,
		enableBooking: false,
	}
];

export default CLIENT_CONFIGS;