const CurrencyService = (function() {
	function setCurrency(currency) {
		localStorage.setItem('currency', currency);
	}
	function getCurrency() {
		return localStorage.getItem('currency');
	}
	return {
		setCurrency,
		getCurrency
	};
})();
export default CurrencyService;
