import React, { useEffect, Suspense } from "react";
import "./App.css";
import "./static/sass/app.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./services/PrivateRoute";
import Congrats from "./screens/Congrats";
import Contact from "./screens/Contact";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core";
import GlobalMuiTheme from "./Theme";
import LoaderCircle from "./components/LoaderCircle";
import { connect } from "react-redux";
import CurrencyService from "./services/CurrencyService/LanguageService";
import { RootState } from "./store/store";
import { RouterProps } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import RouteChange from "./components/RouteChange";
import * as Sentry from "@sentry/react";
import AddLocation from "./screens/admin/AddLocation";

const Header = React.lazy(() => import("./components/Header"));
const Landing = React.lazy(() => import("./screens/Landing"));
const Home = React.lazy(() => import("./screens/Home"));
const Book = React.lazy(() => import("./screens/Book"));
const SignedIn = React.lazy(() => import("./components/SignedIn"));
const CampaignDetails = React.lazy(() => import("./screens/CampaignDetails"));
const TermsAndConditions = React.lazy(
  () => import("./screens/TermsAndConditions")
);
const Customers = React.lazy(() => import("./screens/admin/Customers"));
const Users = React.lazy(() => import("./screens/admin/Users"));
const AddCustomer = React.lazy(() => import("./screens/admin/AddCustomer"));
const RegisterSensor = React.lazy(
  () => import("./screens/admin/RegisterSensor")
);
const AddUser = React.lazy(() => import("./screens/admin/AddUser"));
const AddCoordinates = React.lazy(
  () => import("./screens/admin/AddCoordinates")
);

interface AppProps extends Partial<RouterProps> {
  user: any;
}

function App(props: AppProps) {
  const { t } = useTranslation(undefined, { useSuspense: false });
  const { customerData, currentLocation } = props.user;

  useEffect(() => {
    if (customerData) {
      CurrencyService.setCurrency(customerData.customer.currency);
      currentLocation &&
        Sentry.setUser({
          customerName: customerData.customer.name,
          address: `${customerData.customer.address.city} - ${customerData.customer.address.region}`,
          userName: customerData.user.name,
          email: customerData?.user.email,
          locationId: currentLocation?.locationId,
          locationAddress: `${currentLocation.address.city} - ${currentLocation.address.region}`,
        });
    }
  }, [customerData, currentLocation]);

  useEffect(() => {
    window._paq && window._paq.push(["enableJSErrorTracking"]);
  }, []);

  useEffect(() => {
    const root = document.getElementById("root");
    if (root && props.user.language) {
      root.className = `lang-${props.user.language}`;
    }
  }, [props.user.language]);

  return (
    <MuiThemeProvider theme={GlobalMuiTheme}>
      <StylesProvider injectFirst>
        {/*@ts-ignore*/}
        <Router>
          <Suspense fallback={<LoaderCircle center />}>
            <RouteChange />
          </Suspense>
          <Suspense fallback={<LoaderCircle center />}>
            <Header />
          </Suspense>
          <Switch>
            <Suspense fallback={<LoaderCircle center />}>
              <Route path="/" component={Landing} exact />
              <Route path="/signed-out" component={Landing} />
              <Route path="/signed-in" component={SignedIn} />
              <Route
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              <PrivateRoute path="/home" component={Home} />
              <PrivateRoute path="/book" component={Book} />
              <PrivateRoute
                path="/campaign-details"
                component={CampaignDetails}
              />
              <PrivateRoute path="/congrats" component={Congrats} />
              <PrivateRoute path="/contact" component={Contact} />
              <PrivateRoute path="/customers" component={Customers} />
              <PrivateRoute path="/users/:customerId" component={Users} />
              <PrivateRoute path="/add-customer" component={AddCustomer} />
              <PrivateRoute
                path="/register-sensor/:customerId"
                component={RegisterSensor}
              />
              <PrivateRoute path="/add-user/:customerId" component={AddUser} />
              <PrivateRoute
                path="/add-location/:customerId"
                component={AddLocation}
              />
              <PrivateRoute
                path="/add-coordinates/:customerId"
                component={AddCoordinates}
              />
            </Suspense>
          </Switch>
          {props.user.showLoader ? (
            <div className="globalLoader">
              <LoaderCircle />
              <div className="globalLoader__text">
                <Trans t={t}>global.loading</Trans>
              </div>
            </div>
          ) : (
            ""
          )}
        </Router>
      </StylesProvider>
    </MuiThemeProvider>
  );
}

const mapStateToProps = (state: RootState) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(App);
