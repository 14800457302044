import { Store, applyMiddleware, combineReducers, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import chartReducer from './chart/reducer';
import userReducer from './user/reducer';
import superUserReducer from './superuser/reducer';
import campaignReducer from './campaign/reducer';
import chartLoadState from "./middlewares/chartLoadState";
import chartHighestShownValue from "./middlewares/chartHighestShownValue";

const rootReducer = combineReducers({
	chart: chartReducer,
	user: userReducer,
	campaign: campaignReducer,
	superuser: superUserReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default function configureStore(): Store {
	const middlewares = [thunkMiddleware, chartLoadState, chartHighestShownValue];
	const middlewareEnhancer = applyMiddleware(...middlewares);

	const store = createStore(rootReducer, composeWithDevTools(middlewareEnhancer));

	return store;
}

export const configuredStore = configureStore();