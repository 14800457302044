export interface IPackage {
	TITLE_TRANSLATION_ID: string
	ID: string
	NAME: string
	PRICE: number | string
	RADIUS: number
	ORDER: number
	AUB: boolean
}
export interface IPackages {
	[key: string]: IPackage
}
const PACKAGES: IPackages = {
	ESSENTIAL: {
		TITLE_TRANSLATION_ID: "packages.essential.title",
		ID: "essential",
		NAME: "essential",
		PRICE: 200,
		RADIUS: 1,
		ORDER: 1,
		AUB: false
	},
	PREMIUM: {
		TITLE_TRANSLATION_ID: "packages.premium.title",
		ID: "premium",
		NAME: "Premium",
		PRICE: 500,
		RADIUS: 2.5,
		ORDER: 2,
		AUB: true
	},
	CUSTOM: {
		TITLE_TRANSLATION_ID: "packages.custom.title",
		ID: "custom",
		NAME: "Custom",
		PRICE: "Your price",
		RADIUS: 5,
		ORDER: 3,
		AUB: true
	}
};
export const PACKAGE_ACTION_IDS = {
	UPGRADE: 'upgrade',
	DOWNGRADE: 'downgrade',
	CANCEL: 'cancel'
};
export const PACKAGE_ACTIONS = [
	{
		id: PACKAGE_ACTION_IDS.UPGRADE,
		label: 'Upgrade',
		gerund: 'Upgrading'
	},
	{
		id: PACKAGE_ACTION_IDS.DOWNGRADE,
		label: 'Downgrade',
		gerund: 'Downgrading'
	},
	{
		id: PACKAGE_ACTION_IDS.CANCEL,
		label: 'Cancel',
		gerund: 'Canceling'
	}
];

export default PACKAGES;