import * as React from 'react';

interface LoaderCircleProps {
	center?: boolean;
}

const LoaderCircle = (props:LoaderCircleProps) => {
	const classNames = `
		loaderCircle
		${props.center ? 'loaderCircle--center' : ''}
	`;
	return (
		<div className={classNames}>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
			<div/>
		</div>
	);
};

export default LoaderCircle;