import {SuperUserActions, SuperUserState} from "./types";
const initialState: SuperUserState = {
	overwriteCustomerId: null
};

export default function superUserReducer(state = initialState, action: SuperUserActions) {
	switch (action.type) {
		case 'SET_OVERWRITE_CUSTOMER_ID': {
			return {
				...state,
				overwriteCustomerId: action.payload
			};
		}
		default:
			return state;
	}
}
