import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-xhr-backend";
import nl from "./locales/nl/translation.json";
import de from "./locales/de/translation.json";
import fr from "./locales/fr/translation.json";
import en from "./locales/en/translation.json";

const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  fr: {
    translation: fr,
  },
  nl: {
    translation: nl,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    react: {
      wait: true,
    },
    whitelist: Object.keys(resources),
    resources,
  });

export default i18n;
