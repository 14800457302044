import React from 'react';
import { IAddLocationProps, MapDispatchToProps, MapStateToProps } from './types';
import Menu from "../Menu";
import { RootState } from "../../../store/store";
import { AppThunkDispatch } from "../../../types";
import { addLocation } from "../../../store/superuser/actions";
import { connect } from "react-redux";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { AccountBox } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';
import { useParams } from "react-router-dom";

const mcc = [
	{
		id: 5462,
		name: "Bakeries"
	},
	{
		id: 5813,
		name: "Bar, Lounge Disco, Nightclub"
	},
	{
		id: 7230,
		name: "Barber and Beauty Shop"
	},
	{
		id: 5940,
		name: "Bicycle Shops-Sales"
	},
	{
		id: 5977,
		name: "Cosmetic Stores"
	},
	{
		id: 5912,
		name: "Drug stores, Pharmac"
	},
	{
		id: 5992,
		name: "Florists"
	},
];

const AddLocation = (props: IAddLocationProps) => {
	const [formData, updateFormData] = React.useState<any>({
		locationName: "",
		mccId: "",
		country: "CHE",
		region: "",
		postalCode: "",
		city: "",
		street: ""
	});
	const [selectError, setSelectError] = React.useState<boolean>(false);
	const [submitError, setSubmitError] = React.useState<string | null>(null);
	const [submitSuccess, setSubmitSuccess] = React.useState<boolean>(false);
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

	const { customerId }: any = useParams();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		updateFormData({
			...formData,
			[e.target.name]: e.target.value
		});
		if(e.target.name === 'mccId') {
			setSelectError(false);
		}
	};

	const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
		e.preventDefault();

		setSubmitError(null);
		setSubmitSuccess(false);
		setSelectError(!formData.mccId);

		if(!formData.mccId) return;
		
		setIsSubmitting(true);

		const data = {
			"name": String(formData.locationName),
			"mcc": String(formData.mccId),
			"address": {
				"country": formData.country,
				"region": formData.region,
				"postalCode": formData.postalCode,
				"city": formData.city,
				"street": formData.street
			}
		}

		await props.addLocation(data, customerId).then(() => {
			setSubmitSuccess(true);
			updateFormData({
				locationName: "",
				mccId: "",
				country: "CHE",
				region: "",
				postalCode: "",
				city: "",
				street: ""
			});
		}).catch((e: any) => {
			setSubmitError(e.response.data.error);
		});
		setIsSubmitting(false);
	}
	return (
		<div className="addLocationScreen screen">
			<div className="screen__header">
				<Menu />
			</div>
			<div className="screen__content">
				<CssBaseline />
				<Avatar color="primary">
					<AccountBox />
				</Avatar>
				<Typography className="addLocationScreen__title" component="h1" variant="h5">
					Add Location
                    </Typography>
				<form className="addLocationScreen__form" onSubmit={onSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								id="locationName"
								label="Name"
								name="locationName"
								type="text"
								InputLabelProps={{
									style: { color: '#646464' },
								}}
								value={formData.locationName}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl className="addLocationScreen__mccDropdown">
								<InputLabel className="addLocationScreen__mccDropdownLabel" >Choose MCC</InputLabel>
								<Select
									className="addLocationScreen__mccDropdownSelect"
									id="mccId"
									name="mccId"//@ts-ignore
									value={formData.mccId || ""}
									//@ts-ignore
									onChange={handleChange}
									variant="outlined"
									required
								>
									{mcc.map(location => {
										return (
											<MenuItem
												key={location.id}
												value={location.id}
											>
												{`${location.id} - ${location.name}`}
											</MenuItem>
										)
									})}
								</Select>
								{selectError && <FormHelperText className="addLocationScreen__selectError">This is required!</FormHelperText>}
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<TextField
								autoComplete="country"
								name="country"
								variant="outlined"
								required
								fullWidth
								id="country"
								label="Country"
								InputLabelProps={{
									style: { color: '#646464' },
								}}
								value={formData.country}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								fullWidth
								id="region"
								label="Region"
								name="region"
								autoComplete="email"
								type="text"
								InputLabelProps={{
									style: { color: '#646464' },
								}}
								value={formData.region}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="city"
								label="City"
								name="city"
								autoComplete="city"
								type="text"
								value={formData.city}
								InputLabelProps={{
									style: { color: '#646464' },
								}}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="street"
								label="Street"
								name="street"
								autoComplete="street"
								type="text"
								value={formData.street}
								InputLabelProps={{
									style: { color: '#646464' },
								}}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								variant="outlined"
								required
								fullWidth
								id="postalCode"
								label="Postal Code"
								name="postalCode"
								autoComplete="postalCode"
								type="text"
								value={formData.postalCode}
								InputLabelProps={{
									style: { color: '#646464' },
								}}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<br />
					{submitSuccess && <Typography variant="h6" color="primary" align="center">Success!</Typography>}
					{submitError && <Typography variant="h6" color="error" align="center">Error: {submitError}</Typography>}
					<Button
						className="button button--primary addUserScreen__submit"
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						disabled={isSubmitting}
					>
						Add Location
                        </Button>
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState): MapStateToProps => ({
	superuser: state.superuser
});

const mapDispatchToProps = (dispatch: AppThunkDispatch): MapDispatchToProps => ({
	addLocation: (locationData: any, customerId: string) => dispatch(addLocation(locationData, customerId))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);