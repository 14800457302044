import React from 'react';
import { Route } from 'react-router-dom';
import SignedIn from '../../components/SignedIn';
import TokenService from "../TokenService/TokenService";
import {connect} from "react-redux";

const PrivateRoute = (props) => {

	const token = TokenService.getToken();

	const finalComponent = token && props.user.customerData ? props.component : SignedIn;
	return <Route {...props} component={finalComponent} />;
};

const mapStateToProps = (state) => ({
	user: state.user
});

export default connect(mapStateToProps, {})(PrivateRoute);