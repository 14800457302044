import API from "../../services/API/API";
import LanguageService from "../../services/LanguageService/LanguageService";
import { getCampaignData } from "../campaign/actions";
import { AppThunk } from "../../types";
import { ILocation } from "../../services/API/types";
import {
  ISetLanguageAction,
  IShowLoaderAction,
  UserActionTypes,
} from "./types";

export const loginUser = (): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch({
      type: "LOGIN_USER",
      payload: true,
    });
  };
};
export const getCustomerData = (customerId?: string): AppThunk => {
  return async (dispatch): Promise<void> => {
    let params: any = {};
    if (customerId) {
      params.customerId = customerId;
    }
    const response = await API.get("currentuser", params)
      .catch((e) => {
        // @ts-ignore
        window.document.location = "/";
      })
      .catch(() => {});

    if (!customerId && !!response) {
      var currentLanguage = LanguageService.getLanguage();

      dispatch({
        type: UserActionTypes.SET_LANGUAGE,
        payload: currentLanguage || "en",
      });

      dispatch({
        type: UserActionTypes.SET_CUSTOMER_DATA,
        payload: response.data,
      });
      response.data.locations[0] &&
        dispatch(setCurrentLocation(response.data.locations[0]));
    }

    return response;
  };
};
export const setCurrentLocation = (location: ILocation): AppThunk => {
  return async (dispatch): Promise<void> => {
    dispatch(getCampaignData(location.locationId));

    dispatch({
      type: UserActionTypes.SET_CURRENT_LOCATION,
      payload: location,
    });
  };
};
export const setLanguage = (language: string): ISetLanguageAction => {
  LanguageService.setLanguage(language);

  return {
    type: UserActionTypes.SET_LANGUAGE,
    payload: language,
  };
};
export const setShowLoader = (show: boolean): IShowLoaderAction => {
  return {
    type: UserActionTypes.SHOW_LOADER,
    payload: show,
  };
};
