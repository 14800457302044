import {GRANULARITY} from "./API";

interface IDateTypes {
	[key: string]: string
}
interface IGranularity {
	[key: string]: string
}

interface IChart {
	DATE_TYPES: IDateTypes
	GRANULARITY: IGranularity
	MINIMUM_Y_SCALE_VALUE: number
}

const CHART: IChart = {
	DATE_TYPES: {
		DAY: 'day',
		WEEK: 'week',
		MONTH: 'month',
		CUSTOM: 'custom'
	},
	GRANULARITY: {
		DAY: GRANULARITY.HOUR,
		WEEK: GRANULARITY.HOUR,
		MONTH: GRANULARITY.DAY,
		CUSTOM: GRANULARITY.DAY
	},
	MINIMUM_Y_SCALE_VALUE: 100
};

export default CHART;