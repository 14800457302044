import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { configuredStore } from "./store/store";
import { Provider } from "react-redux";
import "./i18n";
import ErrorBoundary from "./components/ErrorBoundary";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    "https://538db0287dcf400b82eca1b284516cdb@o463796.ingest.sentry.io/5469256",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const Root = () => (
  <Provider store={configuredStore}>
    <ErrorBoundary name="index">
      <App />
    </ErrorBoundary>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
