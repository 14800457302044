import {IPackage} from "../../constants/PACKAGES";
import {ICustomerData, ILocation} from "../../services/API/types";

export interface UserState {
	isLoggedIn: boolean
	customerData: ICustomerData | null
	currentLocation: ILocation | null
	currentPackage: IPackage | null
	language: string,
	hasSensorData: boolean
	showLoader: boolean
	superuser: boolean
	manager: boolean
	overwriteUserCredentials: boolean
}

export enum UserActionTypes {
	LOGIN_USER = "LOGIN_USER",
	SET_CUSTOMER_DATA = "SET_CUSTOMER_DATA",
	SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION",
	SET_LANGUAGE = "SET_LANGUAGE",
	SHOW_LOADER = "SHOW_LOADER"
}
export interface ILoginUserAction {
	type: typeof UserActionTypes.LOGIN_USER;
	payload: boolean;
}
export interface ISetCustomerDataAction {
	type: typeof UserActionTypes.SET_CUSTOMER_DATA;
	payload: any;
}
export interface ISetCurrentLocationAction {
	type: typeof UserActionTypes.SET_CURRENT_LOCATION;
	payload: any;
}
export interface ISetLanguageAction {
	type: typeof UserActionTypes.SET_LANGUAGE;
	payload: string;
}
export interface IShowLoaderAction {
	type: typeof UserActionTypes.SHOW_LOADER;
	payload: boolean;
}

export type UserActions =
	ILoginUserAction |
	ISetCustomerDataAction |
	ISetCurrentLocationAction |
	ISetLanguageAction |
	IShowLoaderAction;